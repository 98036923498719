import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import './Card.styl';

function Card({ children, url }) {
    const [selected, setSelected] = useState();

    const transition = (event) => {
        event.preventDefault();

        setSelected(true);

        setTimeout(() => {
            navigate(url);
        }, 800);
    };

    return (
        <button
            type='button'
            className={clsx('card', { selected })}
            onClick={transition}
        >
            <div className='card-content'>{children}</div>
            <div className='selected-icon'>
                <i className='icon icon-check' />
            </div>
        </button>
    );
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    url: PropTypes.string.isRequired,
};

export default Card;
