import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import SEO from '../components/SEO';
import MainLayout from '../layouts/MainLayout';
import Image from '../components/Image';

import './WindowsTemplate.styl';
import Card from '../components/Card';
import { resolveUrl } from '../../utils/string';
import useFirebase from '../../services/firebase/useFirebase';
import { getCurrentStore } from '../../services/auth';
import { formatDateDDMMYYYY } from '../../utils/dates';

function WindowTemplate({
    data,
    location,
    pageContext: { label, windowsTypes },
    path,
}) {
    const { images } = data;

    const firebase = useFirebase();
    const { id } = getCurrentStore();

    useEffect(() => {
        if (!firebase) return;

        const storeRef = firebase.database().ref(`/stores/${id}`);

        storeRef.transaction((item) => {
            if (item) {
                return {
                    ...item,
                    // eslint-disable-next-line no-underscore-dangle
                    appVersion: process.env.__VERSION__,
                    lastTimeActive: formatDateDDMMYYYY(new Date()),
                };
            }
            return item;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebase]);

    const getImage = (imgName) =>
        images.edges.filter(({ node }) => node.name === imgName)[0].node
            .childImageSharp;

    return (
        <MainLayout
            headerOptions={{
                backButton: true,
            }}
        >
            <SEO
                title='Brico App'
                location={location}
                description='Brico App'
            />
            <div className='content window-types container'>
                <div className='page-title'>
                    Sélectionnez le{' '}
                    <span className='txt-red'>
                        modèle de fenêtres en {label.toUpperCase()}
                    </span>{' '}
                    :
                </div>
                <div className='cards-list'>
                    {windowsTypes.map((type, i) => (
                        <Card
                            key={`window-types-list-${type.label}-${i}`}
                            url={resolveUrl(path, type.slug)}
                        >
                            <Image
                                alt={`home section ${type.label}`}
                                {...getImage(type.image)}
                                backgroundColor='grey'
                                style={{
                                    alignItems: 'center',
                                }}
                            />
                            {type.hasGreyColor !== undefined && (
                                <div className='chip'>Existe en GRIS</div>
                            )}
                            <div className='infos'>
                                {type.label}
                                {type.labelPlus && (
                                    <div className='label-plus'>
                                        <span className='txt-red'>+</span>{' '}
                                        {type.labelPlus}
                                    </div>
                                )}
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        </MainLayout>
    );
}

WindowTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        filter: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        windowsTypes: PropTypes.array.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
};

export default WindowTemplate;

export const query = graphql`
    query ($filter: String!) {
        images: allFile(filter: { relativeDirectory: { eq: $filter } }) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 405
                            height: 260
                            transformOptions: { cropFocus: NORTH }
                            layout: FIXED
                        )
                    }
                }
            }
        }
    }
`;
